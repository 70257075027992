






import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class lmsStudents extends Vue {
  loading = false;
}
